import { t } from '@agentguru/i18n';
export function makeLanguages() {
    return [
        {
            id: 0,
            description: t('constants.en_US'),
            type: 'en_US',
            enabled: true,
        },
        {
            id: 1,
            description: t('constants.pt_BR'),
            type: 'pt_BR',
            enabled: true,
        },
    ];
}
